<template>
  <div>
    <v-row v-if="!data_loaded">
      <v-col v-for="i in 7" :key="i" cols="6" sm="3">
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-card class="mt-10 mb-10" v-if="data_loaded">
      <v-card-title>
        <div class="d-flex align-center">
          <v-icon x-large>payments</v-icon>
          <h1 class="ms-3"> אמצעי תשלום</h1>
        </div>
        <v-spacer></v-spacer>
        <v-btn text exact to="/settings" color="primary">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="basic mt-2">
            <v-col class="py-0" cols="3">
              <v-switch v-model="item.show_visa">
                <template slot="label">
                  <span class="mx-3"> תשלום באשראי </span>
                </template>
              </v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end pa-8">
        <v-btn
          @click="save()"
          :loading="save_loader"
          :disabled="save_loader"
          x-large
          depressed
          height="40"
          color="black white--text ms-3"
          >שמירה</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-snackbar left v-model="snackbar">
      <p class="white--text">ההגדרות עודכנו בהצלחה</p>
      <template v-slot:action>
        <v-icon color="green" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "a",
  data() {
    return {
      snackbar: false,
      data_loaded: false,
      save_loader: false,
      item: {
        show_visa: false,
      },
    };
  },
  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "settings/payments",
            method: "get",
          },
        })
        .then((res) => {
          const data = res.data.data;

          if (data) {
            this.item = Object.assign({}, data);
          }
          this.data_loaded = true;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    save() {
      if (!this.save_loader && this.data_loaded) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "settings/payments",
              method: "POST",
            },
            data: this.item,
          })
          .then((res) => {
            this.save_loader = false;
            this.snackbar = true;
            console.log(res);
          })
          .catch((err) => {
            this.save_loader = false;
          });
      }
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
